<template>
  <div id="shiZhan">
    <!--头部导航-->
    <div style="z-index:9999;position:fixed;top:0;left: 0;">
      <baseNav></baseNav>
    </div>
    <!--内容-->
    <div class="shiZhanCon">
      <!--内容头部-->
      <div class="commenHead">
        <div class="about_tit">
          <p v-if="shizhanData.title&&shizhanData.title.length>0" class="animated bounceInLeft" v-html="shizhanData.title[0].title"></p>
        </div>
        <div class="about_img">
          <div class="right_grid ">
            <img src="../assets/images/pic_grid_r.png" alt="">
          </div>
          <div class="left_grid " style="height: auto;">
            <img src="../assets/images/pic_grid_l.png" alt="">
          </div>
        </div>
      </div>

      <!--具体内容-->
      <div class="shiZhanConBox commenStyle">
        <!--        专家课堂-->
        <div class="zhuanjiaItems commenItem">
          <div class="topLine mgl20"></div>
          <div class="tit_text mgl20">专家课堂</div>
          <div class="itemCon commenCon">
            <div class="swiper-container mySwiper" v-show="zjktList && zjktList.length>0">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="i in zjktList" :key="i.id" @click="toVideoShow(i.video_file)">
                  <div class="items" style="height: 51vw;">
                    <img v-lazy="i.thumb_image" alt="">
                    <div class="icon_video" v-show="i.video_file && i.video_file.length>0"></div>
                    <div class="tit">{{i.title}}</div>
                    <div class="text">{{ i.desc }}<br/>{{i.author}}</div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="items" style="background-color: #071D42;">
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>

          </div>
        </div>
        <!--        手术演示-->
        <div class="shoushuItems commenItem">
          <div class="topLine mgl20"></div>
          <div class="tit_text mgl20">手术演示</div>
          <div class="itemCon commenCon">
            <div class="swiper-container mySwiper"  v-show="sslbList && sslbList.length>0">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="i in sslbList" :key="i.id" @click="toVideoShow(i.video_file)">
                  <div class="items">
                    <img v-lazy="i.thumb_image" alt="">
                    <div class="icon_video" v-show="i.video_file && i.video_file.length>0"></div>
                    <div class="tit">{{i.title}}</div>
                    <div class="text">{{ i.desc }}<br/>{{i.author}}</div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="items" style="background-color: #071D42;">
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>

          </div>
        </div>
        <!--        病例分享-->

        <div class="bingliItems commenItem">
          <div class="topLine mgl20"></div>
          <div class="tit_text mgl20">病例分享</div>
          <div class="itemCon commenCon">
            <div class="swiper-container mySwiper"  v-show="blfxList && blfxList.length>0">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="i in blfxList" :key="i.id" @click="toVideoShow(i.video_file)">
                  <div class="items" style="height: 51.4vw;">
                    <img v-lazy="i.thumb_image" alt="">
                    <div class="icon_video" v-show="i.video_file && i.video_file.length>0"></div>
                    <div class="tit">{{i.title}}</div>
                    <div class="text">{{ i.desc }}<br/>{{i.author}}</div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="items" style="background-color: #071D42;">
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>

          </div>
        </div>
        <div style="margin-top: 6.9444vw;">
          <Copyright></Copyright>
        </div>
      </div>

      <!--    视频弹出层-->
      <van-overlay :show="isVideoShow" @click="closeVideo">
        <div class="videowrapper">
          <video-player class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :playsinline="true"
                        :options="playerOptions">
          </video-player>
        </div>
      </van-overlay>

    </div>
  </div>
</template>

<script>
import baseNav from "./baseNav";
import Copyright from "@/component/Copyright";
import Swiper from 'swiper';
import {GetShizhanData,GetIndexDoc} from "@/api/api";

export default {
  data(){
    return{
      //实战banner
      shizhanData:{},
      //专家课堂
      queryInfo1:{
        type:1,
        from:'m',
      },
      zjktList:[],
      //手术演示
      queryInfo2:{
        type:2,
        from:'m',
      },
      sslbList:[],
      //病例分享
      queryInfo3:{
        type:3,
        from:'m',
      },
      blfxList:[],
      //遮罩层显示
      isVideoShow:false,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4", // 类型
          src: ""  // url地址
        }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      }
    }
  },
 Created() {

  },
  mounted() {
    //获取实战页面banner数据
    this.toGetIndexDoc(6)
    this.toGetShizhanData1()
    this.toGetShizhanData2()
    this.toGetShizhanData3()


  },
  methods:{
    //获取实战页面banner数据
    toGetIndexDoc(data){
      let _this=this
      GetIndexDoc(data)
          .then(r=>{
            let res=r.data
            if(res.status!=1){
              _this.shizhanData=[]
              return
            }
            let apiIndex=res.data
            _this.shizhanData=apiIndex
          })
    },
    toGetShizhanData1(){
      let _this=this
      GetShizhanData(_this.queryInfo1)
        .then(r=>{
          let res=r.data
          if(res.status!=1){
            _this.zjktList=[]
            return
          }
          _this.zjktList=res.data
          new Swiper('.shiZhanConBox .zhuanjiaItems .itemCon .mySwiper',{
            slidesPerView:'auto',
            observer: true,//修改swiper自己或子元素时，自动初始化swiper
            observeParents: true,//修改swiper的父元素时，自动初始化swiper
            pagination: '.swiper-pagination'
          })
        })
    },
    toGetShizhanData2(){
      let _this=this
      GetShizhanData(_this.queryInfo2)
          .then(r=>{
            let res=r.data
            if(res.status!=1){
              _this.sslbList=[]
              return
            }
            _this.sslbList=res.data
            new Swiper('.shiZhanConBox .shoushuItems .itemCon .mySwiper',{
              slidesPerView: 'auto',
              observer: true,//修改swiper自己或子元素时，自动初始化swiper
              observeParents: true,//修改swiper的父元素时，自动初始化swiper
              pagination: '.swiper-pagination',
            })
          })



    },
    toGetShizhanData3(){
      let _this=this
      GetShizhanData(_this.queryInfo3)
          .then(r=>{
            let res=r.data
            if(res.status!=1){
              _this.blfxList=[]
              return
            }
            _this.blfxList=res.data
            new Swiper('.shiZhanConBox .bingliItems .itemCon .mySwiper',{
              slidesPerView: 'auto',
              observer: true,//修改swiper自己或子元素时，自动初始化swiper
              observeParents: true,//修改swiper的父元素时，自动初始化swiper
              pagination: '.swiper-pagination',
            })
          })
    },
    //显示视频
    toVideoShow(data){
      this.playerOptions.sources[0].src = data
      this.isVideoShow = true
    },
    //关闭视频
    closeVideo(){
      let _this=this
      _this.isVideoShow = false
      setTimeout(function (){
        _this.playerOptions.sources[0].src =''
      },500)
    }

  },
  components:{
    baseNav,
    Copyright
  }
}
</script>
<style lang="less" scoped>
#shiZhan{
  padding-top: 13.888vw;
  background-color: #071D42;

}
.shiZhanCon{
  .commenHead{
    background:url("../assets/images/pic_banner_shizhan.jpg") no-repeat -43.611vw -4.722vw;
    background-size: 160%;
  }
  .shiZhanConBox{
    width: 100vw;
    .commenItem{
      padding-bottom: 19.444vw;
      .itemCon{
        position: relative;
        box-sizing: border-box;
        .items{
          padding:2.2222vw;
          border-radius: 1.1111vw;
          width: 100%;
          height: 54.7vw;
          position: relative;
          background-color: rgba(32, 191, 208, 0.1);
          box-sizing: border-box;
          img{
            width: 100%;
            height: 23.611vw;
            object-fit: cover;
            border-radius: 0.5555vw;
          }
          .icon_video{
            display: inline-block;
            width: 9.444vw;
            height: 9.444vw;
            background: url("../assets/images/icon_video.png") no-repeat center center;
            background-size: 100%;
            position: absolute;
            top: 13.055vw;
            left: 50%;
            transform: translate(-50%,-50%);
          }
          .tit{
            font-size:2.777vw;
            line-height: 3.888vw;
            color:#fff;
            font-weight: 700;
            margin:2.2222vw 0 1.1111vw;
            text-align: justify;
          }
          .text{
            font-weight: 400;
            color:#fff;
            font-size:2.777vw;
            text-align: justify;

          }
        }
      }
    }
  }
}
.videowrapper{
  margin-top: 50vh;
  transform: translateY(-50%);
}
.mgl20{
  margin-left: 6.666vw;
}
</style>
<style>
.commenStyle .commenItem .commenCon .swiper-slide{
  margin:0 2.2222vw;
  width: 42.222vw;
}
.commenStyle .swiper-container{
  padding-bottom:8.333vw;
}
.commenStyle .commenItem .commenCon .swiper-slide:first-child {
  margin-left: 6.666vw !important;
}
.commenStyle .commenItem .commenCon .swiper-pagination {
  position: absolute;
  bottom:0;
  left: 50%;
  transform: translateX(-50%);
}
.commenStyle .commenItem .commenCon .swiper-pagination .swiper-pagination-bullet{
  width: 1.1111vw;
  height: 1.1111vw;
  margin: 0 0.5555vw;
  background: rgba(255,255,255,.5);
}
.commenStyle .commenItem .commenCon .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background: #1EB2C5;
}
</style>